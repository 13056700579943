<template>
  <div class="row full-widthq-px-md q-px-lg q-pb-md">
    <configuration-header :breadcrumbs-header="false"></configuration-header>
  </div>
  <div class="configuration-wrapper row full-height q-pt-md q-px-md q-px-lg q-pb-md">
    <section class="col column relative-position configuration-options-tabs">
      <configuration-options-type
        :options="options"
        :active-config="activeConfigTab"
        :target-invalid-choice="targetInvalidChoice"
      ></configuration-options-type>
    </section>

    <section class="configuration-aside-wrapper col-4 col-lg-3">
      <aside class="configuration-aside column full-height no-wrap">
        <!-- Details -->
        <configuration-details></configuration-details>

        <!-- Info (validation, explanation, ignore list) -->
        <configuration-info
          ref="configurationInfo"
          class="q-mt-lg"
          @click:validation-item="displayInvalidChoice"
        ></configuration-info>
      </aside>
    </section>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import notify from '@/utils/notify'
  import {
    CLEAR_LOCKED_CHOICES_IDS,
    CLEAR_POSSIBLE_COMBINATION_CHOICES_IDS,
    CLEAR_SELECTED_CHOICES,
    SET_CURRENT_CONFIGURATION,
    SET_OPTIONS,
    SET_VALIDATION,
    SET_CLEAR_CONFIGURATION_OPTIONS_QUERY, CHANGED_CONFIGURATION
  } from '@/store/modules/pac/mutationTypes'

  import ConfigurationHeader from '@/components/pac/configuration/header/ConfigurationHeader.vue'
  import ConfigurationOptionsType from '@/components/pac/configuration/options/ConfigurationOptionsType.vue'
  import ConfigurationDetails from './details/ConfigurationDetails.vue'
  import ConfigurationInfo from './info/ConfigurationInfo.vue'

  const { mapActions, mapMutations, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'Configuration',
    components: {
      ConfigurationOptionsType,
      ConfigurationDetails,
      ConfigurationInfo,
      ConfigurationHeader
    },
    props: {
      configurationId: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapState([
        'currentConfiguration',
        'options',
        'invalidOptions',
        'selectedChoices'
      ])
    },
    async mounted() {
      await this.getCurrentConfiguration()
      await this.getConfigurationOptions({ sort: 'name,asc' })

      if (this.currentConfiguration.invalidChoices?.length) {
        this.$nextTick(this.notifyAboutMissingChoices)
      }

      if (this.currentConfiguration.ignoreList?.length) {
        this.$refs.configurationInfo?.updateExplanationBadge(this.currentConfiguration.ignoreList.length)
      }
    },
    beforeUnmount() {
      this.CLEAR_LOCKED_CHOICES_IDS()
      this.CLEAR_POSSIBLE_COMBINATION_CHOICES_IDS()
      this.CLEAR_SELECTED_CHOICES()
      this.SET_CURRENT_CONFIGURATION()
      this.SET_OPTIONS()
      this.SET_VALIDATION({ showBadge: false, initialState: true })
      this.SET_CLEAR_CONFIGURATION_OPTIONS_QUERY(true)
      this.CHANGED_CONFIGURATION()
    },
    methods: {
      ...mapActions(['getConfigurationOptions', 'loadConfiguration']),
      ...mapMutations({
        CLEAR_LOCKED_CHOICES_IDS,
        CLEAR_POSSIBLE_COMBINATION_CHOICES_IDS,
        CLEAR_SELECTED_CHOICES,
        SET_CURRENT_CONFIGURATION,
        SET_OPTIONS,
        SET_VALIDATION,
        SET_CLEAR_CONFIGURATION_OPTIONS_QUERY,
        CHANGED_CONFIGURATION
      }),

      async getCurrentConfiguration() {
        this.$q.loading.show({ message: 'Configuration is loading. Please wait ...' })

        try {
          await this.loadConfiguration(this.configurationId)
        }
        finally {
          this.$q.loading.hide()
        }
      },

      notifyAboutMissingChoices() {
        const { invalidChoices } = this.currentConfiguration

        notify({
          title: 'Missing choices',
          content: `The following choices were not found in the Product Model: ${ invalidChoices.join(', ') }`,
          type: 'warning',
          progress: false
        })
      },

      async displayInvalidChoice(targetInvalidChoice = undefined) {
        this.targetInvalidChoice = targetInvalidChoice
      }
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-wrapper {
    padding: 12px 24px;
    gap: 1.5rem;
  }

  .configuration-aside-wrapper {
    position: sticky;
    top: 0;
    height: calc(100vh - 230px);
    max-height: 100%;
    min-width: 400px;
  }

  .configuration-aside {
    max-height: 100%;
  }

  .panel-content {
    height: 75vh;
  }
</style>
