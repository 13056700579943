<template>
  <mi-dialog
    :close-icon="false"
    :model-value="showModal"
    no-esc-dismiss
    no-backdrop-dismiss
    class="learn-more-pac"
  >
    <div class="learn-more-pac__header">
      <h3>Learn more about PAC</h3>
    </div>
    <div class="learn-more-pac__content">
      <div class="q-mr-lg">
        <h6 class="now-you-can">NOW YOU CAN:</h6>
        <div class="new-feature-title flex q-mb-lg">
          <h6 class="q-pl-sm">SORT CHOICES TO DISPLAY VALID FIRST</h6>
          <mi-badge>New</mi-badge>
        </div>
        <p>
          • We reviewed the <span>filter menu</span> and you can now select alternative visualisation for choices.
          <br />
          • The labels were reviewed for better understanding on what is being selected.
        </p>
      </div>
      <img class="learn-more-pac-img" src="@/assets/images/pac/learn-more-pac-img.svg" alt="" />
    </div>

    <template #actions>
      <mi-btn v-close-popup color="accent" @click="onHandleModalClose">Got it</mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  import { getCurrentInstance } from 'vue'
  import MiBtn from '../../packages/@mi-library/MiBtn/MiBtn.vue'
  import MiBadge from '../../packages/@mi-library/MiBadge/MiBadge.vue'

  export default {
    name: 'LearnMorePacModal',
    components: { MiBadge, MiBtn },
    props: {
      showModal: {
        type: Boolean,
        default: false
      }
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const onHandleModalClose = () => {
        proxy.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_LEARN_MORE_PAC_MODAL, false)
      }

      return {
        onHandleModalClose
      }
    }
  }
</script>

<style lang="scss">
  .learn-more-pac {
    .q-card__section {
      padding: 0;
    }

    .q-dialog__inner > .mi-card {
      width: 924px;
      max-width: 924px;
    }

    &__header {
      height: 90px;
      background-color: #eaedf3;
      justify-content: center;

      h3 {
        margin: 0;
        padding: 24px;
      }
    }

    &__content {
      margin: 16px 24px 32px 40px;
      display: flex;

      h6 {
        margin: 0;
      }

      .now-you-can {
        color: #e40045;
        margin-bottom: 16px;
      }

      .new-feature-title {
        gap: 8px;
        border-left: #e40045 4px solid;
        height: 27px;
        align-items: center;
      }

      .q-badge {
        border-radius: 16px;
        padding: 1px 12px 2px;
        width: 46px;
        height: 21px;
      }

      p {
        line-height: 150%;
        letter-spacing: 0;
        padding-left: 5px;
      }

      span {
        font-weight: 700;
      }

      .learn-more-pac-img {
        width: 402px;
        height: 364px;
        margin-top: 43px;
      }
    }

    .mi-card__actions {
      padding-top: 16px;
      padding-right: 24px;
    }
  }
</style>
