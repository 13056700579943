<template>
  <mi-btn-dropdown
    :disable="isConfigurationFillUpInProgress"
    :menu-offset="[0, 4]"
    class="fill-up-menu-btn q-btn--outline"
    label="Fill lists"
    icon-right="none"
    auto-close
    dense
    flat
  >
    <mi-list>
      <!-- Menu options -->
      <mi-list-item
        v-for="({ title }, fillUpType) in FILL_UP_TYPES"
        :key="fillUpType"
        clickable
        @click="handleFillUpOptionClick(fillUpType)"
      >
        <mi-list-item-section>
          <mi-list-item-label> {{ title }} </mi-list-item-label>
        </mi-list-item-section>
      </mi-list-item>
    </mi-list>
  </mi-btn-dropdown>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import {
    CLEAR_SELECTED_CHOICES,
    SET_SELECTED_CHOICES,
    SET_CURRENT_CONFIGURATION
  } from '@/store/modules/pac/mutationTypes'
  import { PAC_CONFIG_FILLED_UP } from '@/utils/analytics/constants'
  import { poll } from '@/api/polling'
  import {
    fillConfigurationAllValidChoices,
    fillConfigurationComplete,
    fillConfigurationSingleValidChoices,
    fillConfigurationValidChoicesData,
    fillConfigurationValidCompleteData
  } from '@/api'

  const { mapActions, mapMutations, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationFillUpMenu',
    data: () => ({
      FILL_UP_TYPES: {
        complete: {
          title: 'With valid choices',
          fillUpFnAsync: fillConfigurationComplete,
          fillUpFnAsyncData: fillConfigurationValidCompleteData
        },
        singleValidChoices: {
          title: 'With only one valid choice',
          fillUpFnAsync: fillConfigurationSingleValidChoices,
          fillUpFnAsyncData: fillConfigurationValidChoicesData
        },
        allValidChoices: {
          title: 'With every valid choice',
          fillUpFnAsync: fillConfigurationAllValidChoices,
          fillUpFnAsyncData: fillConfigurationValidChoicesData
        }
      },
      isConfigurationFillUpInProgress: false,
      clearPoll: null
    }),
    computed: {
      ...mapState({
        currentConfiguration: 'currentConfiguration',
        lockedChoicesIds: 'lockedChoicesIds',
        validationResultsLength: ({ validation = {} } = {}) => validation.results?.length || 0
      })
    },
    beforeUnmount() {
      this.clearPoll?.()
    },
    methods: {
      ...mapActions(['validateCurrentConfigurationV2']),
      ...mapMutations({
        CLEAR_SELECTED_CHOICES,
        SET_SELECTED_CHOICES,
        SET_CURRENT_CONFIGURATION
      }),

      async handleFillUpOptionClick(fillUpType = '') {
        const notificationFn = this.$q.notify({
          message: '(1/2) Validating configuration',
          caption: 'Please wait...',
          type: 'info',
          timeout: 0,
          spinner: true,
          group: false
        })

        this.isConfigurationFillUpInProgress = true

        try {
          await this.getValidateConfiguration()

          if (this.validationResultsLength) {
            notify({
              title: 'Warning',
              content: 'The configuration is invalid and it\'s not possible to fill it up',
              type: 'warning'
            })
          }
          else {
            notificationFn({ message: '(2/2) Filling up configuration' })

            const params = {
              selectedChoices: this.currentConfiguration.selectedChoices,
              planningPeriodRange: this.currentConfiguration.planningPeriodRange,
              encodedBusinessName: this.currentConfiguration.productModel?.encodedBusinessName,
              ruleIdsToIgnore: this.currentConfiguration.ignoreList?.map(rule => rule.id)
            }

            const fillUpTypeObj = this.FILL_UP_TYPES[fillUpType]

            const choices = await this.fillUpAsync(fillUpTypeObj, params)

            this.updateSelectedChoices(choices)

            // Analytics
            recordAnalytics(
              PAC_CONFIG_FILLED_UP,
              { type: this.FILL_UP_TYPES[fillUpType].title }
            )
          }
        }
        finally {
          this.isConfigurationFillUpInProgress = false

          notificationFn()
        }
      },
      async fillUpAsync(fillUpTypeObj, params) {
        const { executePoll, clearPoll } = poll()
        this.clearPoll = clearPoll

        const { choices } = await executePoll({
          correlationIdEndpoint: {
            getCorrelationId: fillUpTypeObj?.fillUpFnAsync,
            params
          },
          asyncEndpoint: fillUpTypeObj?.fillUpFnAsyncData
        })

        return choices
      },
      async getValidateConfiguration() {
        const { executePoll, clearPoll } = poll()
        this.clearPoll = clearPoll
        return this.validateCurrentConfigurationV2(executePoll)
      },
      updateSelectedChoices(choices = []) {
        this.CLEAR_SELECTED_CHOICES()
        this.SET_SELECTED_CHOICES(choices)
        this.SET_CURRENT_CONFIGURATION({
          ...this.currentConfiguration,
          selectedChoices: choices.map(choice => ({
            choice,
            locked: !!this.lockedChoicesIds.get(choice.optionId)?.includes(choice.id)
          }))
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .fill-up-menu-btn.mi-btn.q-btn--dense {
    height: 32px;
    width: 67px;

    ::v-deep(.q-btn__content) {
      line-height: 22px;
    }
  }
</style>
