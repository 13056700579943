<template>
  <mi-form ref="form">
    <mi-text-field
      v-model="optionsSearchString"
      bg-color="white"
      placeholder="Search for: Option or Choice"
      clearable
      hide-bottom-space
      outlined
      :rules="[minLength(3)]"
      :error="showError"
      @clear="clearOptionsSearchField"
    >
      <template #prepend>
        <mi-btn
          class="search-field-submit-btn"
          icon="search"
          icon-type
          type="submit"
          dense
          fab
          flat
          @click="handleSubmit"
        ></mi-btn>
      </template>
    </mi-text-field>
  </mi-form>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { base64EncodeUnicode } from '@/utils/base64'
  import { minLength } from '@/utils/validators'

  import { SET_CLEAR_CONFIGURATION_OPTIONS_QUERY } from '@/store/modules/pac/mutationTypes'

  const { mapActions, mapMutations, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationHeaderSearchField',
    data: () => ({
      optionsSearchString: '',
      showError: false,
      minLength
    }),
    computed: {
      ...mapState(['clearConfigurationOptionsQuery'])
    },
    watch: {
      clearConfigurationOptionsQuery: {
        async handler(newValue) {
          if (newValue) {
            if (this.optionsSearchString.length) {
              await this.clearOptionsSearchField()
            }

            this.SET_CLEAR_CONFIGURATION_OPTIONS_QUERY(false)
          }
        }
      }
    },
    methods: {
      ...mapActions(['getConfigurationOptions']),
      ...mapMutations({ SET_CLEAR_CONFIGURATION_OPTIONS_QUERY }),

      async clearOptionsSearchField() {
        this.optionsSearchString = ''

        await this.getConfigurationOptions({ sort: 'name,asc' })
      },
      searchConfigurationOptions(query = '') {
        const searchQuery = query?.split(';')
          .map(item => base64EncodeUnicode(item.trim()))
          .filter(item => item.length)
          .join(',') || ''
        this.getConfigurationOptions({
          query: searchQuery,
          sort: 'name,asc',
          isPac: true
        })
      },
      async handleSubmit() {
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.showError = false
          this.searchConfigurationOptions(this.optionsSearchString)
        }
        else {
          this.showError = true
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-field-submit-btn.mi-btn.q-btn--dense {
    padding: 6px;
    margin-left: -6px;

    ::v-deep(.mi-icon) {
      font-size: 20px;
    }
  }
</style>
