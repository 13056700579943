<template>
  <div class="column full-width">
    <div>
      <h3 class="pac-header"> {{ currentConfiguration.name }} </h3>
    </div>
    <div class="row justify-between items-center q-pt-sm">
      <!-- Search field -->
      <div class="col-3 col-xl-2">
        <search-field></search-field>
      </div>

      <div class="col-auto">
        <!-- Save configuration dialog trigger -->
        <mi-btn-dropdown
          :menu-offset="[0, 4]"
          class="q-ml-sm"
          label="Save configuration"
          auto-close
        >
          <save-menu
            @open:save-dialog="openSaveDialog"
          ></save-menu>
        </mi-btn-dropdown>

        <!-- Export configuration menu -->
        <mi-btn-dropdown
          :disable-dropdown="isConfigurationExporting"
          :loading="isConfigurationExporting"
          :menu-offset="[0, 4]"
          class="q-ml-sm"
          label="Export"
          auto-close
        >
          <export-menu
            :execute-poll-ref="executePollRef"
            @update:is-exporting="isConfigurationExporting = $event"
            @open:windchill-dialog="openWindchillDialog"
          ></export-menu>
        </mi-btn-dropdown>
      </div>
    </div>

    <!-- Save configuration dialog -->
    <save-dialog
      v-model="isSaveConfigurationDialogShown"
      :save-type="isSaveConfigurationDialogType"
      @saved:new-configuration="handleConfigurationSavedEvent"
    ></save-dialog>

    <!-- PAC Windchill interface dialog -->
    <configuration-windchill-dialog
      v-if="isWindchillDialogShown"
      v-model="isWindchillDialogShown"
    ></configuration-windchill-dialog>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import SaveDialog from '@/components/pac/configuration/dialogs/ConfigurationSaveDialog.vue'
  import ConfigurationWindchillDialog
    from '@/components/pac/configuration/windchill-interface/ConfigurationWindchillDialog.vue'
  import { poll } from '@/api/polling'
  import { PAC_SAVE_TYPES } from '@/constants'
  import ExportMenu from './ConfigurationHeaderExportMenu.vue'
  import SaveMenu from './ConfigurationHeaderSaveMenu.vue'
  import SearchField from './ConfigurationHeaderSearchField.vue'

  const { mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationHeader',
    components: {
      ExportMenu,
      SaveMenu,
      SaveDialog,
      SearchField,
      ConfigurationWindchillDialog
    },
    data: () => ({
      isSaveConfigurationDialogShown: false,
      isSaveConfigurationDialogType: PAC_SAVE_TYPES.NONE,
      isConfigurationExporting: false,
      isWindchillDialogShown: false,
      clearPollRef: null,
      executePollRef: null
    }),
    computed: {
      ...mapState({
        currentConfiguration: state => state.currentConfiguration || {}
      })
    },
    created() {
      const { executePoll, clearPoll } = poll()
      this.clearPollRef = clearPoll
      this.executePollRef = executePoll
    },
    beforeUnmount() {
      this.clearPollRef?.()
    },
    methods: {
      openWindchillDialog() {
        this.isWindchillDialogShown = true
      },
      openSaveDialog(type) {
        this.isSaveConfigurationDialogShown = true
        this.isSaveConfigurationDialogType = type
      },
      handleConfigurationSavedEvent(id) {
        this.$router.push({ name: this.$router.currentRoute.name, params: { id } })

        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        }, 5000)
      }
    }
  }
</script>

<style lang="scss">
  .pac-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin: 10px 0;
  }
</style>
